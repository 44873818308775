// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b0_kw";
export var caseStudyBackground__lineColor = "b0_kt";
export var caseStudyHead__imageWrapper = "b0_nc";
export var caseStudyProjectsSection = "b0_kx";
export var caseStudyQuote__bgRing = "b0_kr";
export var caseStudyQuote__bottomVideo = "b0_kR";
export var caseStudyQuote__light = "b0_mD";
export var caseStudyQuote__lightLast = "b0_nd";
export var caseStudyVideo__ring = "b0_kG";
export var caseStudy__bgDark = "b0_km";
export var caseStudy__bgLight = "b0_kl";
export var caseStudy__bgLightReverse = "b0_l6";