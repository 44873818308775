import { Link } from 'gatsby'
import { StaticImage, getImage } from 'gatsby-plugin-image'

import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './Malleni.module.scss'
import useMalleniStaticQuery from './useMalleniStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const quotes = {
  client: {
    name: 'CEO',
    position: 'E-commerce Marketplace Company, United Kingdom',
  },
  designer: {
    name: 'Anastasia',
    position: 'UI/UX Designer at Codica',
  },
  be: {
    name: 'Serhii',
    position: 'Tech Lead & Rails expert at Codica',
  },
}

const previewTraderVsTitle = 'Recreational Vehicles Marketplace Platform'
const previewPmkTitle = 'Online Service Marketplace'

const CaseStudiesMalleni = () => {
  const query = useMalleniStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const solutionDeliveredSeven = getImage(
    fileToImageLikeData(query.solutionDeliveredSeven),
  )
  const solutionDeliveredEight = getImage(
    fileToImageLikeData(query.solutionDeliveredEight),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <CaseStudyHead
        title="Custom E-commerce Solution"
        subtitle="Learn how Codica delivered the first such a large multi-vendor marketplace in the UK and Europe for users to offer products and services within one custom e-commerce solution."
        maxWidthSubtitle="400px"
        maxWidthInfo="366px"
        image={bannerCase}
        classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
        imageAlt="Custom multi-vendor marketplace | Codica"
        imageTitle="Custom multi-vendor website | Codica"
        domain="E-commerce"
        location="London, United Kingdom"
        timeline="December 2020 - October 2021"
        services={
          <>
            <Link key="productKey" to="/services/product-discovery/">
              Product discovery
            </Link>
            , Front-end, Back-end,
            <Link to="/services/ui-ux-design/" className="ml5" key="designKey">
              UX/UI design services
            </Link>
            ,
            <Link
              to="/services/online-marketplace-development/"
              className="ml5"
              key="marketplaceDevKey"
            >
              Online marketplace development
            </Link>
          </>
        }
        team="3 Back-end Developers, 3 Front-end Developers, 1 UX/UI Designer, 2 QA Engineers, 1 Project Manager"
        technologies="Ruby, Ruby on Rails, React, Next.js, PostgreSQL, AWS, Elasticsearch, Redis, Docker"
        isQuote
      />

      <CaseStudyQuote
        quote={[
          {
            text: '“We turned Codica with a request to build a complex marketplace from scratch. The development team made us feel involved every step of the way from the beginning and was focused on our needs.',
          },
          {
            text: 'As a result, we received a robust and easy-to-use web solution with all desired features. We are happy with our decision to choose the Codica team. They are definitely experts in their domain.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/AvatarDraft.png"
            alt="CEO, E-commerce Marketplace Company, United Kingdom"
            title="CEO, E-commerce Marketplace Company"
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name="CEO"
        position="E-commerce Marketplace Company, United Kingdom"
      />

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft={[
          'The central idea behind this custom e-commerce development was to create a web solution that enables users to do many things using just one platform by switching between its parts if needed.',
          <div className="mb-3" key="bgTextLeft" />,
          'This project is a multi-vendor marketplace platform that has no analogs in the UK or Europe. It provides many options to businesses, allowing them to offer services on the Local platform’s part and the main website.',
        ]}
        bgTextRight={[
          'At the same time, customers can sell their goods on the World platform’s page and share the shopping experience with one another on the Connect web page.',
          <div className="mb-3" key="bgTextRight" />,
          'Our custom e-commerce website development started with the minimum viable product creation. Thanks to the MVP development, we collected the first user feedback and included additional functionality in the solution.',
        ]}
        sectionBusiness={[
          {
            label:
              'Create a large online marketplace platform that comprises B2C and C2C segments.',
          },
          {
            label:
              'Make the platform attractive and user-friendly to increase user engagement and retention.',
          },
          {
            label:
              'Optimize the marketplace solution for high visibility on search engines.',
          },
          {
            label:
              'Build the secure, reliable, and scalable multi-vendor marketplace platform according to the latest tech standards.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Verify the marketplace business idea with the help of an MVP platform and improve the custom e-commerce solution based on the collected data.',
          },
          {
            label:
              'Developed the minimalistic design of a custom e-commerce solution that is inviting, eye-pleasing, and intuitive.',
          },
          {
            label:
              'Performed competent Search Engine Optimization (SEO) of the marketplace to make the custom multi-vendor website receive more visitors.',
          },
          {
            label:
              'Provided an option for users to find services by choosing a location radius due to the geolocation and map integration.',
          },
        ]}
      />

      <div className={containerStyles.caseStudyQuote__light}>
        <CaseStudyQuote
          quote={[
            {
              text: '“For large and complex projects, thoughtful design is critical, as it makes it possible to use all the functionality and features of the product conveniently. This project is just that.',
            },
            {
              text: 'It is a large marketplace that combines several platforms for different user needs. In creating designs for this multi-vendor marketplace, it was necessary to think through complex flows in detail. We also solved many non-trivial tasks to provide the most enjoyable experience of interacting with the product.',
            },
            {
              text: 'I was happy to work on this project as part of a professional and motivated team to provide our clients with a high-quality result.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/Designer-Anastasia.png"
              alt={`${quotes.designer.name}, ${quotes.designer.position} at Codica`}
              title={`${quotes.designer.name}, ${quotes.designer.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.designer.name}
          position={quotes.designer.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Looking for a reliable team to build a custom web platform?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Easy switching between platform parts"
          description={[
            'Our specialists customized the multi-vendor platform so users can find what they are looking for much faster. The main page of the solution combines all the parts of the platform, such as Local, World, and Connect pages. Each of these B2C (Local) and C2C (Connect and World) sections has a separate home page.',
            <div className="mb-2" key="descr" />,
            'In the upper part of the marketplace’s main website, there is a menu for quickly switching between all platform parts.',
          ]}
          alt="Switching between the parts of the custom marketplace | Codica"
          imageTitle="Switching between the parts of the online marketplace | Codica"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Convenient search filters"
          description={[
            'We created convenient search filters, shortening the customers’ buying journey. Now, users can easily navigate their results on the custom multi-vendor marketplace and find what they’re looking for.',
            <div className="mb-2" key="descr" />,
            'Thanks to this improvement, we made the web solution more customer-oriented and thus enhanced the marketplace conversion rate.',
          ]}
          alt="Search filters on the online marketplace platform | Codica"
          imageTitle="Search filters on the online marketplace | Codica"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="SEO for more traffic"
          description={[
            'Our SEO specialists worked hard to make this custom e-commerce solution meet the requirements of search engines. They optimized meta tags, connected analytics, customization, and detailed reporting.',
            <div className="mb-2" key="descrOne" />,
            'Furthermore, SEO experts advised on web optimization in terms of technical perspective and consulted about reducing the website’s download speed.',
            <div className="mb-2" key="descrTwo" />,
            'Also, they compiled the robots.txt and sitemap.xml files correctly. These files assist search engines in better understanding the particular website and indexing it properly.',
          ]}
          alt="SEO optimization of the custom multi-vendor website | Codica"
          imageTitle="SEO optimization for the online marketplace | Codica"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Full-featured admin panel"
          description={
            <>
              In terms of custom
              <Link
                to="/services/ecommerce-marketplace-development/"
                className="ml5"
                key="eCommernceKey"
              >
                e-commerce marketplace development
              </Link>
              , we made a clean-looking and easy-to-use admin panel. With its
              help, the marketplace’s managers can easily handle advertisements,
              product and service categories, store creation requests, and more.
              <div className="mb-2" key="descr" />
              In general, this solution for website admins makes database
              management of this marketplace visually appealing and exceedingly
              comfortable.
            </>
          }
          alt="Admin panel on the custom multi-vendor website | Codica"
          imageTitle="Admin panel on the online marketplace platform | Codica"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Interactive sign-up form"
          description={[
            'Codica’s experts worked on custom marketplace development to create the interactive sign-up feature for sellers. So, when users want to register on the website to sell products or services, they should fill out the questionnaire form. To do this, they answer relevant questions.',
            <div className="mb-2" key="descrOne" />,
            'The sign-up form is engaging and convenient, with icons on which vendors can click to answer questions. Based on their answers, suppliers are offered the best suitable type of membership for them in the end.',
            <div className="mb-2" key="descrTwo" />,
            'Thus, the small stores do not pay as much for a subscription as the large ones. Also, if the user provides only services, they will not pay for the functionality of selling products.',
          ]}
          alt="Sign-up form on the custom multi-vendor website | Codica"
          imageTitle="Sign-up form on the online marketplace platform | Codica"
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Stripe payment integration"
          description={[
            'The Stripe system is distinguished by the high speed of payment processing, security, and ease of use. This stable and reliable system is great for making international online payments.',
            <div className="mb-2" key="descr" />,
            'Based on these attributes, we chose Stripe as a payment system for this online marketplace case study. We used the Stripe Connect service to assist in automatically onboarding vendors and enabling payout globally.',
          ]}
          alt="Payment integration on the custom multi-vendor website | Codica"
          imageTitle="Payment integration on the online marketplace platform | Codica"
        />
      </div>

      <div className={containerStyles.caseStudy__bgLightReverse}>
        <CaseStudySolution
          image={solutionDeliveredSeven}
          title="Google Maps integration"
          description={[
            'One of the Local platform’s part key features is that users can find services in their location radius. To implement this feature, we integrated Google maps into the Local platform’s page.',
            <div className="mb-2" key="descr" />,
            'If customers want to see services further away from their location, they can use filters to see as many options as they would like.',
          ]}
          alt="Maps integration on the custom multi-vendor website | Codica"
          imageTitle="Maps integration on the online marketplace platform | Codica"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredEight}
          title="Calendar planner"
          description={[
            'We worked on custom marketplace development to make customers of the Local platform’s part search for services conveniently. They also had to order services close to them.',
            <div className="mb-2" key="descrOne" />,
            'To simplify this process, we implemented a calendar on this online services marketplace. Consumers can see the calendar on the supplier’s page and choose the needed date from available time slots to book a service.',
            <div className="mb-2" key="descrTwo" />,
            'After that, the Manager reviews this request and approves it or calls the customer if they needs to clarify or change something.',
          ]}
          alt="Calendar planner on the custom multi-vendor website | Codica"
          imageTitle="Calendar planner on the online marketplace platform | Codica"
        />
      </div>

      <div className={containerStyles.caseStudy__bgLightReverse}>
        <LazyHydrate whenVisible>
          <CaseStudyVideoCase
            title="How it works"
            description={
              <>
                The video below demonstrates how the
                <Link
                  to="/services/multi-vendor-marketplace-development/"
                  className="mx5"
                  key="videoSectionKey"
                >
                  multi-vendor marketplace development
                </Link>
                solution works. Here, users can not only buy or sell products,
                such as clothes or accessories but order services by chosen
                location.
              </>
            }
            ringsClass={containerStyles.caseStudyVideo__ring}
            image={videoCaseImage}
            videoLink="https://www.youtube.com/embed/cfkqR5U-J84"
          />
        </LazyHydrate>

        <div className={containerStyles.caseStudyQuote__bottomVideo}>
          <CaseStudyQuote
            quote={[
              {
                text: '“Our goal was to make a convenient and quick search for the right products or services for users and a functional admin panel that covers the requirements of both large stores and private sellers. In a short period, we managed to implement a complex product with a large number of user flows and various integrations.”',
              },
            ]}
            photo={
              <StaticImage
                src="../../../../assets/images/avatars/Sergey.png"
                alt={`${quotes.be.name}, ${quotes.be.position} at Codica`}
                title={`${quotes.be.name}, ${quotes.be.position}`}
                {...imageProps}
              />
            }
            bgRing={containerStyles.caseStudyQuote__bgRing}
            name={quotes.be.name}
            position={quotes.be.position}
          />
        </div>
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudyTech
          technologies={[
            { label: 'Ruby', icon: 'ruby' },
            { label: 'Ruby On Rails', icon: 'ror' },
            { label: 'React JS', icon: 'react' },
            { label: 'Next.js', icon: 'nextjs' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'AWS', icon: 'aws' },
            { label: 'Elasticsearch', icon: 'elasticsearch' },
            { label: 'Redis', icon: 'redis' },
          ]}
          integrations={[
            { label: 'Stripe', icon: 'stripe' },
            { label: 'Stripe Connect', icon: 'stripeConnect' },
            { label: 'Anycable', icon: 'anycable' },
            { label: 'React Admin', icon: 'reactAdmin' },
            { label: 'Docker', icon: 'docker' },
            { label: 'Docker Compose', icon: 'dockerCompose' },
            { label: 'Gitlab', icon: 'gitlab' },
            { label: 'App Search', icon: 'appSearch' },
          ]}
        />
      </div>

      <div className={containerStyles.caseStudyQuote__lightLast}>
        <CaseStudyQuote
          isBottom
          quote={[
            {
              text: '“The Codica company is a team of professionals. Together we created a unique solution for the market in Europe and the United Kingdom that allows buying and selling products and providing services in one place.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/AvatarDraft.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s turn your idea into a successful product!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewTraderVsTitle}
              color="tradervsColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/tradervs.png"
                  alt={`Case study: ${previewTraderVsTitle} | Codica`}
                  title={`Case study: ${previewTraderVsTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="online-marketplace-for-caravans"
            />
            <CaseStudyPreview
              title={previewPmkTitle}
              color="pmkColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/pmk.png"
                  alt={`Case study: ${previewPmkTitle} | Codica`}
                  title={`Case study: ${previewPmkTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="kids-activities-service-marketplace"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesMalleni
